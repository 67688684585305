import StraightTrack from "../images/svg/track_straight.svg";
import CurvedTrack from "../images/svg/track_curved.svg";
import Cross from "../images/svg/cross.svg";
import Question from "../images/svg/question.svg";
import {
    cellCoordToBoardCoord,
    CELL_SIZE,
    TrackType,
} from "../utils/board-utils";

interface Props {
    trackType: TrackType;
    x: number;
    y: number;
}

const getTrackImage = (trackType: TrackType) => {
    switch (trackType) {
        case "up-down":
        case "left-right":
            return StraightTrack;
        case "crossed":
            return Cross;
        case "question":
            return Question;
        default:
            return CurvedTrack;
    }
};

const getTrackRotation = (trackType: TrackType) => {
    switch (trackType) {
        case "question":
        case "crossed":
        case "up-down":
            return 0;
        case "left-right":
            return 90;
        case "up-right":
            return -90;
        case "right-down":
            return 0;
        case "down-left":
            return 90;
        case "left-up":
            return 180;
    }
    console.error(`Missing rotation: ${trackType}`);
    return 0;
};

export const Track: React.FC<Props> = ({ trackType, x, y }) => {
    if (trackType === "empty") {
        return null;
    }

    const TrackImage = getTrackImage(trackType);
    const coordinates = cellCoordToBoardCoord(x, y);
    const rotation = getTrackRotation(trackType);

    return (
        <g
            transform={`translate(${coordinates.x}, ${
                coordinates.y
            }) rotate(${rotation} ${CELL_SIZE / 2} ${CELL_SIZE / 2})`}
        >
            <TrackImage width={CELL_SIZE} height={CELL_SIZE} />
        </g>
    );
};
