import { LegacyRef, useCallback, useEffect, useRef, useState } from "react";
import { cellCoordToBoardCoord, CELL_SIZE } from "../utils/board-utils";
import { useDragContext } from "./DragProvider";
import { useGameContext } from "./GameContext";

const getPositionInCellSpace = (
    e: React.MouseEvent,
    boardDimensions: { x: number; y: number }
) => {
    const rect = (e.target as SVGElement).getBoundingClientRect();
    const xCellSize = rect.width / boardDimensions.x;
    const x = Math.floor((e.clientX - rect.left) / xCellSize);

    const yCellSize = rect.height / boardDimensions.y;
    const y = Math.floor((e.clientY - rect.top) / yCellSize);
    if (x < 0 || x >= boardDimensions.x || y < 0 || y >= boardDimensions.y) {
        return;
    }
    return { x, y };
};

const getPositionInCellSpaceTouch = (
    e: React.TouchEvent | TouchEvent,
    boardDimensions: { x: number; y: number }
) => {
    const touch = e.touches[0];
    if (!touch) {
        return;
    }

    const rect = (e.target as SVGElement).getBoundingClientRect();
    const xCellSize = rect.width / boardDimensions.x;
    const x = Math.floor((touch.clientX - rect.left) / xCellSize);

    const yCellSize = rect.height / boardDimensions.y;
    const y = Math.floor((touch.clientY - rect.top) / yCellSize);
    if (x < 0 || x >= boardDimensions.x || y < 0 || y >= boardDimensions.y) {
        return;
    }
    return { x, y };
};

export const InteractCell: React.FC = () => {
    const {
        puzzle: { boardDimensions },
    } = useGameContext();
    const coordinates = cellCoordToBoardCoord(0, 0);
    const { dispatch, previousAction, mode } = useGameContext();
    const [useTouch, setUseTouch] = useState(false);
    const { isDragging } = useDragContext();

    const ref: LegacyRef<SVGRectElement> = useRef(null);

    const onDrag = useCallback(
        ({ x, y }: { x: number; y: number }) => {
            if (!isDragging.current) {
                return;
            }

            if (mode === "track") {
                return;
            }

            if (previousAction.current?.type === "place") {
                const p = previousAction.current.position;
                if (p.x === x && p.y === y) {
                    return;
                }
            }
            dispatch({ type: "place", position: { x, y } });
        },
        [dispatch, isDragging, mode, previousAction]
    );

    const onTouchMove = useCallback(
        (e: TouchEvent) => {
            e.preventDefault();
            const position = getPositionInCellSpaceTouch(e, boardDimensions);
            position && onDrag(position);
        },
        [boardDimensions, onDrag]
    );

    useEffect(() => {
        // TODO this reattaches every time the board state changes, which is really not ideal.
        const tm = onTouchMove;
        const r = ref.current;
        r?.addEventListener("touchmove", tm);
        return () => r?.removeEventListener("touchmove", tm);
    }, [onTouchMove]);

    return (
        <rect
            ref={ref}
            style={{ touchAction: "none" }}
            onMouseDown={(e) => {
                if (!useTouch && e.button !== 2) {
                    const position = getPositionInCellSpace(e, boardDimensions);
                    position && dispatch({ type: "place", position });
                }
            }}
            onMouseMove={(e) => {
                if (!useTouch) {
                    const position = getPositionInCellSpace(e, boardDimensions);
                    position && onDrag(position);
                }
            }}
            onTouchStart={(e) => {
                if (!useTouch) {
                    setUseTouch(true);
                }
                const position = getPositionInCellSpaceTouch(
                    e,
                    boardDimensions
                );
                position && dispatch({ type: "place", position });
            }}
            onContextMenu={(e) => {
                e.preventDefault();
                const position = getPositionInCellSpace(e, boardDimensions);
                position && dispatch({ type: "delete", position });
            }}
            width={CELL_SIZE * boardDimensions.x}
            height={CELL_SIZE * boardDimensions.y}
            opacity={0}
            transform={`translate(${coordinates.x}, ${coordinates.y})`}
        />
    );
};
