import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Board } from "../components/Board";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { css } from "@emotion/react";
import { GameContextProvider } from "./GameContext";
import { generate } from "../generator/generator";
import { decodeGame } from "../utils/board-state-encoding";
import { Controls } from "./Controls";
import { getLocaleDateString, userLocale } from "../locale";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../db";
import { DifficultySelector } from "./DifficultySelector";
import { pregeneratedPuzzles } from "../pregenerated-puzzles";

const dateContainer = css`
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 16px 0;
    & > * + * {
        margin-left: 8px;
    }
`;

const today = new Date();
today.setHours(0, 0, 0, 0);

const difficultyDimensionMapping = (difficulty: "easy" | "medium" | "hard") => {
    switch (difficulty) {
        case "easy":
            return { x: 5, y: 6 };
        case "medium":
            return { x: 6, y: 6 };
        case "hard":
            return { x: 8, y: 8 };
    }
};

export const ControlsAndBoard: React.FC = () => {
    const [date, setDate] = useState<Date>(new Date());
    const seed = date.toDateString();
    const [boardCode, setBoardCode] = useState<string | undefined>();

    const [_difficulty, setDifficulty] = useState<"medium" | "hard">("medium");

    const pregennedHard =
        `${seed} ${difficultyDimensionMapping("hard").x} ${
            difficultyDimensionMapping("hard").y
        }` in pregeneratedPuzzles;
    const difficulty =
        _difficulty === "hard" && !pregennedHard ? "medium" : _difficulty;

    const [boardDimensions, setBoardDimensions] = useState<{
        x: number;
        y: number;
    }>(difficultyDimensionMapping(difficulty));

    useEffect(() => {
        setBoardDimensions(difficultyDimensionMapping(difficulty));
    }, [difficulty]);

    useEffect(() => {
        const boardCode = new URLSearchParams(document.location.search).get(
            "board"
        );
        setBoardCode(boardCode ?? undefined);
    }, []);

    const saveFile = useMemo(() => {
        if (!boardCode) {
            return generate(boardDimensions, seed);
        }

        return decodeGame(boardCode);
    }, [boardCode, boardDimensions, seed]);

    const puzzleCellState = useLiveQuery(() => db.puzzleCellState.toArray());
    const completeSeeds: Record<string, boolean> = useMemo(
        () =>
            puzzleCellState?.reduce((acc: Record<string, boolean>, entry) => {
                const dateString = entry.seed.slice(0, 15);
                acc[dateString] = entry.complete;
                return acc;
            }, {}) ?? {},
        [puzzleCellState]
    );

    const selectPreviousIncompletePuzzle = useCallback(
        (prev = true) => {
            const delta = prev ? -1 : 1;
            let _today = date;
            while (true) {
                const yesterday = new Date(_today);
                yesterday.setDate(_today.getDate() + delta);
                yesterday.setHours(0, 0, 0, 0);
                const isComplete = completeSeeds[yesterday.toDateString()];

                if (yesterday > today) {
                    const d = new Date(date);
                    d.setDate(date.getDate() + 1);
                    setDate(d);
                    return;
                }

                if (!isComplete) {
                    setDate(yesterday);
                    return;
                }
                _today = yesterday;
            }
        },
        [completeSeeds, date]
    );

    return (
        <GameContextProvider
            key={`${seed} ${boardDimensions.x} ${boardDimensions.y}`}
            initialSaveFile={saveFile}
        >
            <div css={dateContainer}>
                <button onClick={() => selectPreviousIncompletePuzzle(true)}>
                    {"<"}
                </button>
                <div>
                    <DatePicker
                        selected={date}
                        onChange={(d) => d && setDate(d)}
                        maxDate={today}
                        minDate={new Date("2021-01-01")}
                        locale={userLocale}
                        dateFormat={getLocaleDateString()}
                        dayClassName={(date) => {
                            const dString = new Date(date).toDateString();
                            const isComplete = completeSeeds[dString];
                            if (isComplete) {
                                return "complete-date";
                            }

                            if (dString in completeSeeds) {
                                return "incomplete-date";
                            }

                            return "";
                        }}
                    />
                </div>
                <button
                    onClick={() => selectPreviousIncompletePuzzle(false)}
                    disabled={date >= today}
                >
                    {">"}
                </button>
            </div>
            <DifficultySelector
                difficulty={difficulty}
                setDifficulty={setDifficulty}
                disabledHard={!pregennedHard}
            />
            <Board />
            <Controls />
        </GameContextProvider>
    );
};
