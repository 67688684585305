import Dexie, { Table } from "dexie";

export interface PuzzleCellState {
    seed: string;
    cellState: string;
    complete: boolean;
}

export const db = new Dexie("daily-train-tracks") as Dexie & {
    puzzleCellState: Table<PuzzleCellState>;
};

db.version(1).stores({
    puzzleCellState: "seed,complete",
});
