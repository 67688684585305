/**
 * Solution is list of corner coordinates (plus start and end)
 */
export const solutionToTrackCount = (
    solution: { x: number; y: number }[],
    boardDimensions: { x: number; y: number }
): { x: number[]; y: number[] } => {
    const board: boolean[][] = [];

    for (let y = 0; y < boardDimensions.y; y++) {
        const row: boolean[] = [];
        board.push(row);

        for (let x = 0; x < boardDimensions.x; x++) {
            row.push(false);
        }
    }

    // Fill in corners of solution
    for (const c of solution) {
        board[c.y][c.x] = true;
    }

    // Fill in gaps in solution
    for (let i = 0; i < solution.length - 1; i++) {
        const c0 = solution[i];
        const c1 = solution[i + 1];

        // Exactly one coordinate must be the same. If not, something's wrong here
        if (c0.x === c1.x) {
            // Fill in vertical gaps
            let pointer = Math.min(c0.y, c1.y) + 1;
            while (pointer < Math.max(c0.y, c1.y)) {
                board[pointer][c0.x] = true;
                pointer += 1;
            }
        } else if (c0.y === c1.y) {
            // Fill in horizontal gaps
            let pointer = Math.min(c0.x, c1.x) + 1;
            while (pointer < Math.max(c0.x, c1.x)) {
                board[c0.y][pointer] = true;
                pointer += 1;
            }
        } else {
            console.error("Tried to count tracks for invalid solution");
            return { x: [], y: [] };
        }
    }

    const trackCount: { x: number[]; y: number[] } = { x: [], y: [] };

    for (let x = 0; x < boardDimensions.x; x++) {
        const sum = board.reduce((acc, row) => acc + (row[x] ? 1 : 0), 0);
        trackCount.x.push(sum);
    }

    for (let y = 0; y < boardDimensions.y; y++) {
        const sum = board[y].reduce((acc, x) => acc + (x ? 1 : 0), 0);
        trackCount.y.push(sum);
    }

    return trackCount;
};
