import { css } from "@emotion/react";
import { useGameContext } from "./GameContext";
import StraightTrack from "../images/svg/track_straight.svg";
import Cross from "../images/svg/cross.svg";
import Question from "../images/svg/question.svg";

const switchContainer = css`
    display: flex;
    justify-content: center;
    padding-bottom: 16px;
`;

const switchStyles = css`
    border-radius: 8px;
    border: 1px solid #cccccc;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
`;

const switchIndividual = css`
    cursor: pointer;
    padding: 8px;
    :hover {
        background: #f9e7ca;
    }
`;

export const Controls: React.FC = () => {
    const { dispatch, mode } = useGameContext();

    return (
        <div>
            <div css={switchContainer}>
                <div css={switchStyles}>
                    <div
                        css={switchIndividual}
                        onMouseDown={() =>
                            dispatch({ type: "set-mode", mode: "track" })
                        }
                        onTouchStart={() =>
                            dispatch({ type: "set-mode", mode: "track" })
                        }
                        style={
                            mode === "track" ? { background: "#ebca97" } : {}
                        }
                    >
                        <StraightTrack width={40} height={40} />
                    </div>
                    <div
                        css={switchIndividual}
                        onMouseDown={() =>
                            dispatch({ type: "set-mode", mode: "question" })
                        }
                        onTouchStart={() =>
                            dispatch({ type: "set-mode", mode: "question" })
                        }
                        style={
                            mode === "question" ? { background: "#ebca97" } : {}
                        }
                    >
                        <Question width={40} height={40} />
                    </div>
                    <div
                        css={switchIndividual}
                        onMouseDown={() =>
                            dispatch({ type: "set-mode", mode: "cross" })
                        }
                        onTouchStart={() =>
                            dispatch({ type: "set-mode", mode: "cross" })
                        }
                        style={
                            mode === "cross" ? { background: "#ebca97" } : {}
                        }
                    >
                        <Cross width={40} height={40} />
                    </div>
                </div>
            </div>
        </div>
    );
};
