import { css } from "@emotion/react";

const switchContainer = css`
    display: flex;
    justify-content: center;
    padding-bottom: 16px;
`;

const switchStyles = css`
    border-radius: 8px;
    border: 1px solid #cccccc;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
`;

const switchIndividual = css`
    width: 100px;
    user-select: none;
    display: flex;
    justify-content: center;
    cursor: pointer;
    padding: 8px;
    :hover {
        background: #f9e7ca;
    }
`;

export const DifficultySelector: React.FC<{
    difficulty: "medium" | "hard";
    setDifficulty: (d: "medium" | "hard") => void;
    disabledHard: boolean;
}> = ({ difficulty, setDifficulty, disabledHard }) => {
    return (
        <div>
            <div css={switchContainer}>
                <div css={switchStyles}>
                    <div
                        css={switchIndividual}
                        onMouseDown={() => setDifficulty("medium")}
                        onTouchStart={() => setDifficulty("medium")}
                        style={
                            difficulty === "medium"
                                ? { background: "#ebca97" }
                                : {}
                        }
                    >
                        Medium
                    </div>
                    {!disabledHard && (
                        <div
                            css={switchIndividual}
                            onMouseDown={() => setDifficulty("hard")}
                            onTouchStart={() => setDifficulty("hard")}
                            style={
                                difficulty === "hard"
                                    ? { background: "#ebca97" }
                                    : {}
                            }
                        >
                            Hard
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
