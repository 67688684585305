import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { db } from "../db";
import { useLiveQuery } from "dexie-react-hooks";
import {
    decodeBoardCellState,
    encodeBoardCellState,
} from "../utils/board-state-encoding";
import {
    cellIsFixed,
    fillCellState,
    Puzzle,
    TrackType,
} from "../utils/board-utils";
import { getTrackTypeOnPlace } from "../utils/get-tracktype-on-place";
import { solutionToTrackCount } from "../utils/solution-to-track-count";
import JSConfetti from "js-confetti";
import {
    isPuzzleComplete,
    solutionToCorrectCellState,
} from "../utils/is-puzzle-complete";

type GameAction =
    | { type: "undo" }
    | { type: "redo" }
    | { type: "set-mode"; mode: InteractionMode }
    | { type: "delete"; position: { x: number; y: number } }
    | { type: "place"; position: { x: number; y: number } };

type InteractionMode = "track" | "cross" | "question";

export type GameContext = {
    previousAction: React.MutableRefObject<GameAction | undefined>;
    dispatch: (action: GameAction) => void;
    puzzle: Puzzle & {
        trackCounts: {
            x: number[];
            y: number[];
        };
    };
    cellState: TrackType[][];
    mode: InteractionMode;
};

export type SaveFile = {
    puzzle: Puzzle;
    cellState: TrackType[][];
};

const PGameContext = createContext<GameContext>(null as any);

export const useGameContext = () => {
    return useContext(PGameContext);
};

interface Props {
    initialSaveFile: SaveFile;
}

export const GameContextProvider: React.FC<Props> = ({
    initialSaveFile,
    children,
}) => {
    const {
        puzzle: { seed, boardDimensions, fixedCells, solution },
    } = initialSaveFile;

    const [jsConfetti, setJsConfetti] = useState<JSConfetti | undefined>();
    const previousAction = useRef<GameAction | undefined>();
    useEffect(() => setJsConfetti(new JSConfetti()), []);

    const solutionCellState = useMemo(() => {
        return solutionToCorrectCellState(solution, boardDimensions);
    }, [boardDimensions, solution]);

    const initialPuzzleTrackCounts = useMemo(
        () =>
            solutionToTrackCount(
                initialSaveFile.puzzle.solution,
                boardDimensions
            ),
        [boardDimensions, initialSaveFile.puzzle.solution]
    );

    const [cellState, _setCellState] = useState<TrackType[][]>(
        fillCellState(initialSaveFile.cellState, boardDimensions)
    );

    const [loadedCellStateFromSave, setLoadedCellStateFromSave] =
        useState(false);

    const savedCellState = useLiveQuery(() =>
        db.puzzleCellState.where("seed").equals(seed).first()
    );

    useEffect(() => {
        if (loadedCellStateFromSave) {
            return;
        }

        if (!savedCellState) {
            return;
        }

        if (seed === savedCellState.seed) {
            _setCellState(
                decodeBoardCellState(savedCellState.cellState, boardDimensions)
            );
            setLoadedCellStateFromSave(true);
        }
    }, [boardDimensions, loadedCellStateFromSave, savedCellState, seed]);

    const [cellStatePast, setCellStatePast] = useState<string[]>([]);
    const [cellStateFuture, setCellStateFuture] = useState<string[]>([]);
    const [mode, setMode] = useState<InteractionMode>("track");

    const setCellState = useCallback(
        (newCellState: TrackType[][]) => {
            setCellStatePast((prev) => [
                ...prev,
                encodeBoardCellState(cellState),
            ]);
            _setCellState(newCellState);

            const puzzleComplete = isPuzzleComplete(
                newCellState,
                solutionCellState
            );
            if (puzzleComplete) {
                jsConfetti?.addConfetti();
            }

            // Set encoded state in IndexedDB
            db.puzzleCellState.put({
                seed,
                cellState: encodeBoardCellState(newCellState),
                complete: puzzleComplete,
            });
        },
        [cellState, jsConfetti, seed, solutionCellState]
    );

    const setCellTrackType = useCallback(
        (trackType: TrackType, position: { x: number; y: number }) => {
            if (cellState[position.y][position.x] === trackType) {
                return;
            }

            const newCellState = [...cellState];
            newCellState[position.y] = [...newCellState[position.y]];
            newCellState[position.y][position.x] = trackType;

            setCellState(newCellState);
        },
        [cellState, setCellState]
    );

    const reduce = useCallback(
        (action: GameAction) => {
            switch (action.type) {
                case "set-mode":
                    setMode(action.mode);
                    return;
                case "undo": {
                    if (cellStatePast.length === 0) {
                        return;
                    }
                    setCellStateFuture((prev) => [
                        encodeBoardCellState(cellState),
                        ...prev,
                    ]);
                    _setCellState(
                        decodeBoardCellState(
                            cellStatePast[cellStatePast.length - 1],
                            boardDimensions
                        )
                    );
                    setCellStatePast((prev) => {
                        const clone = [...prev];
                        clone.pop();
                        return clone;
                    });

                    return;
                }
                case "redo": {
                    if (cellStateFuture.length === 0) {
                        return;
                    }
                    setCellStatePast((prev) => [
                        ...prev,
                        encodeBoardCellState(cellState),
                    ]);
                    _setCellState(
                        decodeBoardCellState(
                            cellStateFuture[0],
                            boardDimensions
                        )
                    );
                    setCellStateFuture((prev) => {
                        const clone = [...prev];
                        clone.shift();
                        return clone;
                    });
                    return;
                }
                case "delete": {
                    const position = action.position;

                    if (cellIsFixed(position, fixedCells)) {
                        return;
                    }

                    const trackType = cellState[position.y][position.x];
                    if (trackType === "empty") {
                        setCellTrackType("question", position);
                    } else if (trackType === "question") {
                        setCellTrackType("crossed", position);
                    } else {
                        setCellTrackType("empty", position);
                    }

                    return;
                }
                case "place": {
                    if (cellIsFixed(action.position, fixedCells)) {
                        return;
                    }

                    if (mode === "cross") {
                        const trackType =
                            cellState[action.position.y][action.position.x];
                        if (trackType !== "empty") {
                            setCellTrackType("empty", action.position);
                        } else {
                            setCellTrackType("crossed", action.position);
                        }
                    } else if (mode === "question") {
                        setCellTrackType("question", action.position);
                    } else {
                        // === 'track
                        const trackType = getTrackTypeOnPlace(
                            cellState,
                            boardDimensions,
                            action.position
                        );

                        setCellTrackType(trackType, action.position);
                    }

                    return;
                }
            }
        },
        [
            boardDimensions,
            cellState,
            cellStateFuture,
            cellStatePast,
            fixedCells,
            mode,
            setCellTrackType,
        ]
    );

    const dispatch = useCallback(
        (action: GameAction) => {
            previousAction.current = action;
            reduce(action);
        },
        [reduce]
    );

    return (
        <PGameContext.Provider
            value={{
                previousAction,
                dispatch,
                puzzle: {
                    ...initialSaveFile.puzzle,
                    trackCounts: initialPuzzleTrackCounts,
                },
                cellState,
                mode,
            }}
        >
            {children}
        </PGameContext.Provider>
    );
};
