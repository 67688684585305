import { TrackType } from "./board-utils";

/**
 * The 4 connecting cells are either:
 *  empty -> valid
 *  crossed -> invalid
 *  connecting track -> mandatory
 *  unconnecting track -> invalid
 * Cases:
 * - >2 mandatory: cycle through all track
 * - 2 mandatory: place only track option
 * - 1 mandatory:
 *   - 0 valid
 *   - 1 valid: place only track option
 *   - >1 valid: cycle through available track options
 * - 0 mandatory:
 *   - >2 valid: cycle through available options
 *   - 2 valid: place only track option
 *   - <2 valid: cycle through all track
 */

type ConnectingCell = "up" | "down" | "left" | "right";
type ConnectingCellType = "valid" | "invalid" | "mandatory";

const getConnectingCellTypes = (
    cellState: TrackType[][],
    boardDimensions: { x: number; y: number },
    position: { x: number; y: number }
) => {
    const connectingCells: Record<ConnectingCell, ConnectingCellType> = {
        up: "invalid",
        down: "invalid",
        left: "invalid",
        right: "invalid",
    };

    // Up
    if (position.y > 0) {
        const upTrackType = cellState[position.y - 1][position.x];
        if (upTrackType === "empty" || upTrackType === "question") {
            connectingCells.up = "valid";
        } else if (
            upTrackType === "up-down" ||
            upTrackType === "right-down" ||
            upTrackType === "down-left"
        ) {
            connectingCells.up = "mandatory";
        }
    }

    // Down
    if (position.y < boardDimensions.y - 1) {
        const downTrackType = cellState[position.y + 1][position.x];
        if (downTrackType === "empty" || downTrackType === "question") {
            connectingCells.down = "valid";
        } else if (
            downTrackType === "up-down" ||
            downTrackType === "up-right" ||
            downTrackType === "left-up"
        ) {
            connectingCells.down = "mandatory";
        }
    }

    // Left
    if (position.x > 0) {
        const leftTrackType = cellState[position.y][position.x - 1];
        if (leftTrackType === "empty" || leftTrackType === "question") {
            connectingCells.left = "valid";
        } else if (
            leftTrackType === "left-right" ||
            leftTrackType === "up-right" ||
            leftTrackType === "right-down"
        ) {
            connectingCells.left = "mandatory";
        }
    }

    // Right
    if (position.x < boardDimensions.x - 1) {
        const rightTrackType = cellState[position.y][position.x + 1];
        if (rightTrackType === "empty" || rightTrackType === "question") {
            connectingCells.right = "valid";
        } else if (
            rightTrackType === "left-right" ||
            rightTrackType === "down-left" ||
            rightTrackType === "left-up"
        ) {
            connectingCells.right = "mandatory";
        }
    }

    return connectingCells;
};

const trackTypes: TrackType[] = [
    "up-down",
    "left-right",
    "up-right",
    "right-down",
    "down-left",
    "left-up",
];

export const getTrackTypeOnPlace = (
    cellState: TrackType[][],
    boardDimensions: { x: number; y: number },
    position: { x: number; y: number }
): TrackType => {
    const connectingCells = getConnectingCellTypes(
        cellState,
        boardDimensions,
        position
    );

    const mandatoryCount = Object.values(connectingCells).reduce(
        (acc, c) => acc + (c === "mandatory" ? 1 : 0),
        0
    );

    const validCount = Object.values(connectingCells).reduce(
        (acc, c) => acc + (c === "valid" ? 1 : 0),
        0
    );

    const currentTrackOnTile = cellState[position.y][position.x];

    if (
        mandatoryCount > 2 ||
        (mandatoryCount === 1 && validCount === 0) ||
        (mandatoryCount === 0 && validCount < 2)
    ) {
        // Cycle through all track, since this is an invalid state
        const currentTrackTypeIndex = trackTypes.indexOf(currentTrackOnTile);
        return trackTypes[(currentTrackTypeIndex + 1) % trackTypes.length];
    }

    if (mandatoryCount === 2) {
        // There is only one valid option for this tile
        const mandatoryConnectingCells = Object.entries(connectingCells)
            .filter((cc) => cc[1] === "mandatory")
            .map((cc) => cc[0]) as ConnectingCell[];
        console.assert(mandatoryConnectingCells.length === 2);

        const matchingTrackType = trackTypes.find(
            (tt) =>
                tt.includes(mandatoryConnectingCells[0]) &&
                tt.includes(mandatoryConnectingCells[1])
        );
        if (!matchingTrackType) {
            console.error(
                `Could not find matching track type for mandatoryCount 2. MandatoryCells: ${mandatoryConnectingCells}`
            );
            return "left-right";
        }

        return matchingTrackType;
    }

    if (mandatoryCount === 1) {
        const mandatoryConnectingCell = Object.entries(connectingCells)
            .filter((cc) => cc[1] === "mandatory")
            .map((cc) => cc[0])[0] as ConnectingCell;
        const validConnectingCells = Object.entries(connectingCells)
            .filter((cc) => cc[1] === "valid")
            .map((cc) => cc[0]) as ConnectingCell[];
        console.assert(validConnectingCells.length > 0);

        const validTrackTypesForMandatoryCell = trackTypes.filter((tt) =>
            tt.includes(mandatoryConnectingCell)
        );
        const validTrackTypes = validTrackTypesForMandatoryCell.filter((tt) =>
            validConnectingCells.some((c) => tt.includes(c))
        );
        const currentTrackTypeIndex =
            validTrackTypes.indexOf(currentTrackOnTile);
        return validTrackTypes[
            (currentTrackTypeIndex + 1) % validTrackTypes.length
        ];
    }

    if (mandatoryCount === 0) {
        const validConnectingCells = Object.entries(connectingCells)
            .filter((cc) => cc[1] === "valid")
            .map((cc) => cc[0]) as ConnectingCell[];
        console.assert(validConnectingCells.length > 1);
        const validTrackTypes = trackTypes.filter((tt) => {
            // This is the most disgusting thing that has ever exited my brain
            const trackTypeSplit = tt.split("-") as ConnectingCell[];
            return (
                validConnectingCells.includes(trackTypeSplit[0]) &&
                validConnectingCells.includes(trackTypeSplit[1])
            );
        });

        console.assert(validTrackTypes.length > 0);
        const currentTrackTypeIndex =
            validTrackTypes.indexOf(currentTrackOnTile);
        return validTrackTypes[
            (currentTrackTypeIndex + 1) % validTrackTypes.length
        ];
    }

    console.error("Mandatory/valid case not covered");
    return "left-right";
};
