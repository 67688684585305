import {
    compressToEncodedURIComponent,
    decompressFromEncodedURIComponent,
} from "lz-string";
import { SaveFile } from "../components/GameContext";
import { TrackType } from "./board-utils";

const trackTypes: TrackType[] = [
    "crossed",
    "up-down",
    "left-right",
    "up-right",
    "right-down",
    "down-left",
    "left-up",
    "empty",
    "question",
];

const trackTypeToNumber: Record<TrackType, number> = {} as Record<
    TrackType,
    number
>;
const numberToTrackType: Record<number, TrackType> = {};

for (let i = 0; i < trackTypes.length; i++) {
    trackTypeToNumber[trackTypes[i]] = i;
    numberToTrackType[i] = trackTypes[i];
}

const numberToNBitBinary = (n: number, bits: number): string => {
    return n.toString(2).padStart(bits, "0");
};

export const encodeBoardCellState = (cellState: TrackType[][]) => {
    let cellStateBitString = cellState
        .flat()
        .map((tt) => numberToNBitBinary(trackTypeToNumber[tt], 4))
        .join("");

    const paddingAmount = 8 - (cellStateBitString.length % 8);
    for (let i = 0; i < paddingAmount; i++) {
        cellStateBitString += "0";
    }

    // The first char of the ascii tells us how much padding has been added to the
    // bit string to make it divisible by 8
    let ascii = `${paddingAmount}`;

    for (let i = 0; i < cellStateBitString.length / 8; i++) {
        const binaryChar = cellStateBitString.substring(i * 8, (i + 1) * 8);
        const char = String.fromCharCode(parseInt(binaryChar, 2));
        ascii += char;
    }

    const base64String = btoa(ascii);
    return base64String;
};

export const decodeBoardCellState = (
    code: string,
    dimensions: { x: number; y: number }
): TrackType[][] => {
    const asciiString = atob(code);

    // The first char of the ascii tells us how much padding has been added to the
    // bit string to make it divisible by 8
    const paddingAmount = parseInt(asciiString[0], 10);

    let bitString = "";

    for (let i = 1; i < asciiString.length; i++) {
        const charCode = asciiString.charCodeAt(i);
        const charInBinary = numberToNBitBinary(charCode, 8);
        if (i < asciiString.length - 1) {
            bitString += charInBinary;
        } else {
            // Consider padding (since it's the last character)
            bitString += charInBinary.substring(0, 8 - paddingAmount);
        }
    }

    const flatTrackTypeArray: TrackType[] = [];

    for (let i = 0; i < bitString.length / 4; i++) {
        const bitSubstring = bitString.substring(i * 4, (i + 1) * 4);
        const trackTypeNumber = parseInt(bitSubstring, 2);
        flatTrackTypeArray.push(numberToTrackType[trackTypeNumber]);
    }

    const trackTypeArray = [];
    while (flatTrackTypeArray.length) {
        trackTypeArray.push(flatTrackTypeArray.splice(0, dimensions.x));
    }

    return trackTypeArray;
};

const numberToAsciiChars = (n: number, requiredAsciiChars: number) => {
    const bitString = n.toString(2).padStart(8 * requiredAsciiChars, "0");
    let ascii = "";
    for (let i = 0; i < requiredAsciiChars; i++) {
        const bitStringForChar = bitString.substring(i * 8, (i + 1) * 8);
        const char = String.fromCharCode(parseInt(bitStringForChar, 2));
        ascii += char;
    }

    return ascii;
};

const asciiCharsToNumber = (ascii: string) => {
    let bitString = "";

    for (let i = 0; i < ascii.length; i++) {
        const charCode = ascii.charCodeAt(i);
        const charInBinary = numberToNBitBinary(charCode, 8);
        bitString += charInBinary;
    }

    return parseInt(bitString, 2);
};

export const encodeCoordinateArray = (
    coordinates: { x: number; y: number }[],
    dimensions: { x: number; y: number }
): string => {
    const maxMultipliedCoordinate = dimensions.x * dimensions.y;
    const requiredAsciiChars = Math.ceil(maxMultipliedCoordinate / 2 ** 8);

    const multipliedCoordinateArray = coordinates.map(
        (c) => c.x + c.y * dimensions.x
    );
    const ascii = multipliedCoordinateArray
        .map((n) => numberToAsciiChars(n, requiredAsciiChars))
        .join("");

    return ascii;
};

export const decodeCoordinateArray = (
    code: string,
    dimensions: { x: number; y: number }
): { x: number; y: number }[] => {
    const maxMultipliedCoordinate = dimensions.x * dimensions.y;
    const requiredAsciiChars = Math.ceil(maxMultipliedCoordinate / 2 ** 8);

    const coordinateArray = [];

    for (
        let i = 0;
        i < code.length / requiredAsciiChars;
        i += requiredAsciiChars
    ) {
        const multipliedCoordinates = asciiCharsToNumber(
            code.substring(i, i + requiredAsciiChars)
        );
        const x = multipliedCoordinates % dimensions.x;
        const y = Math.floor(multipliedCoordinates / dimensions.x);
        coordinateArray.push({ x, y });
    }

    return coordinateArray;
};

export const encodeGame = (saveFile: SaveFile): string => {
    const {
        cellState,
        puzzle: { fixedCells, boardDimensions, solution },
    } = saveFile;
    const cellCode = encodeBoardCellState(cellState);
    const fixedCellsCode = encodeCoordinateArray(fixedCells, boardDimensions);
    const solutionCode = encodeCoordinateArray(solution, boardDimensions);

    // Assumption that neither x nor y are greater than 1023 baked in
    const fixedCellsLength = fixedCellsCode.length
        .toString(32)
        .padStart(2, "0");
    const solutionLength = solutionCode.length.toString(32).padStart(2, "0");
    const xString = boardDimensions.x.toString(32).padStart(2, "0");
    const yString = boardDimensions.y.toString(32).padStart(2, "0");
    return compressToEncodedURIComponent(
        `${xString}${yString}${fixedCellsLength}${fixedCellsCode}${solutionLength}${solutionCode}${cellCode}`
    );
};

export const decodeGame = (code: string): SaveFile => {
    const decompressed = decompressFromEncodedURIComponent(code) ?? "";
    const xString = decompressed.substring(0, 2);
    const yString = decompressed.substring(2, 4);
    const fixedCellsLength = parseInt(decompressed.substring(4, 6), 32);
    const fixedCellsCode = decompressed.substring(6, 6 + fixedCellsLength);
    const solutionLength = parseInt(
        decompressed.substring(6 + fixedCellsLength, 6 + fixedCellsLength + 2),
        32
    );
    const solutionCode = decompressed.substring(
        6 + fixedCellsLength + 2,
        6 + fixedCellsLength + 2 + solutionLength
    );
    const cellCode = decompressed.substring(
        6 + fixedCellsLength + 2 + solutionLength
    );

    const boardDimensions = {
        x: parseInt(xString, 32),
        y: parseInt(yString, 32),
    };

    const solution = decodeCoordinateArray(solutionCode, boardDimensions);

    return {
        puzzle: {
            boardDimensions,
            solution: solution,
            fixedCells: decodeCoordinateArray(fixedCellsCode, boardDimensions),
        },
        cellState: decodeBoardCellState(cellCode, boardDimensions),
    };
};
