export const pregeneratedPuzzles = {
    "Sun Jan 23 2022 8 8":
        "̀\ud804Ɛ\u0000适怮ǰࣀ昨᐀态쀋*(ÀഃHŀЀ“@•\b`ӀࡀࢀᔒⰁ렀怃䀄耩਀硨耀",
    "Mon Jan 24 2022 8 8":
        "̀\ud807臨ڀ䐄怰ን˰ྀﰇ憀爁쀗RH聢Є‒褀頂谀᐀ࠀ怄쀈耉\u0014hᑀ",
    "Tue Jan 25 2022 8 8": "̀\ud803\t聘ీ砂〉阄‑©핔ĸˠᮀ晸Ơ᪑ưـऀ䐀က耀@@ƀ̀ƀ଀᐀᠀㡤䀀",
    "Wed Jan 26 2022 8 8":
        "̀\ud81cǠ؀젂昰૫ǀఀꀅ莀ခ耂\nHĐɀ䁬ƈĠᒀ㈀ꀆ耋f␀렂瀄昄\u0000",
    "Thu Jan 27 2022 8 8":
        "̀\ud81càऀ㠁ꀏĘૅπ쁠ʀȀ　䀂\t\u0004\b`@Ԁ鐁倍䀱D°䀍肀ऀ刀렀‏䀎耭ሀⰨ耀",
    "Fri Jan 28 2022 8 8":
        "̀\ud81e聀ᨀࠂ怯Ę೅ΐἀ码逊䀮¨ ̀ⰀꀰఇPĀࠀ␀ဈрҀ଀㘀戀䐀ꢐ怍䀆耋Vᑀ",
    "Sat Jan 29 2022 8 8":
        "̀\ud807腸ᘀ␆ꌀ\udd60嘂퀎聤ʐ஀Ḁꀏ8`ʁ쀪(à඀㄂Ñ‌쀉耕Jĸ䄀",
    "Sun Jan 30 2022 8 8":
        "̀\ud80b脈Ԁ䠁耾ę蔂熀᐀ↀ䰀뀆쀇ก逐ᄀЀ \u0010H ڂ䀐 Àǀր઀ഀḊ ",
    "Mon Jan 31 2022 8 8": "̀\ud810Ɛऀ标쀏〆䀂\bȠঀᘀ䐃ဂ䀎Ø\u0010À̀̀Ⰰꠈς䀜耙R´Ř°ꈀ",
    "Tue Feb 01 2022 8 8": "̀\ud81f脸ༀ标䀨《肬°ೀ㜀就耣Ȑঀᘀ䠃‍耎¸ʐ̤䒀㰀㠀뀨耀",
    "Wed Feb 02 2022 8 8":
        "̀\ud81f腨ڀ堁‐Ę೅ǰ䃬ϐเᤀ琰ીଜ͐怷\\ı가᐀ခ耦Ȁ\ud801袐Ԡ஀܀਀᠀퀀ꁑ\u0000",
    "Thu Feb 03 2022 8 8":
        "̀\ud800Pಀ됆쀧Ę픂\bP̀ಃ8ˠੇxƠ຀㸀퀇䂀㐀逌䒀ᘀ䰀᠀ࠂငꀅ䀉贊 ",
    "Fri Feb 04 2022 8 8":
        "̀\ud81d胨 간怨〆폁נހᘁ順聎ゐኀ਀ࠄ–\f\u0010ሀ᐀栀　倂ꀅ腄\u0000",
    "Sat Feb 05 2022 8 8": "̀\ud81b脘စ耺《耴͐ೀጌɱ者ᰂဉ耆\bࠉ\u0014Ð̀Ȁ؉P`àƠꈀ",
    "Sun Feb 06 2022 8 8":
        "̀\ud840蠄腠Ԁ ㎕Ӡᜁ렌쀵Ä퀅䀥ɠր㘀㢰ੀᤀ码ꀎ耼8码倀ꀆ䀄芈\u0000",
    "Mon Feb 07 2022 8 8":
        "̀\ud81c腐ࠀ氁〈쁳⠌聸Ơ຀㰀耊,ఀꀌ耴À瀈$ɠРр଀㘀ᰀ␁䠂퀃ꀏ䄀㼀㸄Ř˱ 쀆蔀⨀䰁頃瀂캘က",
    "Tue Feb 08 2022 8 8":
        '̀\ud821퀔腨䈀訇ꀹǱꀞpÀ΀ᘀ吁瀃쀌脀栁 lƐڀ̀Ѐ　 老 䈠ࢀጙ"Tƨ̰ť䐀',
    "Wed Feb 09 2022 8 8":
        "̀\ud80b耠ऀⰇꀸ〆】頀ꀀ而\u0010怒Dİ˃聤ưؠ᪀昄Ơ ⤀㈀瑈ΐ߀຀ḅက",
    "Thu Feb 10 2022 8 8":
        "̀\ud81fƐঘ 0ۥǠಀꐅ쀇\u0012hڠᢀ氘ɀ଀∁ࠄꀑ踀렄\u0002\u0018\u0010ȠҀČ蠀",
    "Fri Feb 11 2022 8 8":
        "̀\ud814Šᜀ球ĘӅƀᰀ샀⨜Ǡྀ㤀䒀밀쀳,Ðɀᢀ㘀ᰀ⠀怃䀆䀄耊$Dᑀ",
    "Sat Feb 12 2022 8 8":
        "̀\ud808Ā਀搇怿〆\u0002\bȠइH̠ഀ　 耋\u001cðΠڀḀ렂ꀁ䀃耄耩Ȁ琑儀",
    "Sun Feb 13 2022 8 8":
        "̀\ud81e胸ހ頂䀸ĘӅΰᾆည쀯ᰀ뀌쀵TɐŀĀ萈ƀȀဂ䀈蒀㈀᐀ꠀ퀁‎䀟:xᑀ",
    "Mon Feb 14 2022 8 8":
        "̀\ud808Đᢀ⠅<Ę೅ŀሀ耀\u0001\u0002ఁ 耑\u0010Ōᨂ瀅쀷<˰ՠˀڀ㔜$pခ耂Ҁ᠀㠔䀀",
    "Tue Feb 15 2022 8 8":
        "̀\ud80f聸ᄀ퀂‸Ę໅˰샜Űӆ肜态䀁\u0018ɡ쀤PĀఀ胈͠ք耕j4ŨʐĠ΀ᜀ⨀堀　䀂考ń\u0000",
    "Wed Feb 16 2022 8 8": "̀\ud802頛ऀ`ᗆ‧¸Ӏ㌀\udc00쀫´ǐཀ㤀搂逋耪(â쀖㰀傈Ƞ@Č蠀",
    "Thu Feb 17 2022 8 8":
        "̀\ud81c臰ഀ耧ę謁瀈ᰀ、䀅耆\u0010ƀȀਉĀ\u0000ȀЁဂ䀙6,0\u0010¦څက",
    "Fri Feb 18 2022 8 8":
        "̀\ud80f胨ᴀ堄ᥕ˰ᖀ됰Ẁ爁ഀ吁态者dƠ؀ࠀ䀁 䀉Ā∀쐁ꠃ。怅䀒锊 ",
    "Sat Feb 19 2022 8 8":
        '̀\ud81cøԀ뀅\u0001Ę˅πᴀ栁䀹ôΰ䌀밂뀋䀍$ʐ஀⨜À \fऀက쀁ꀃ 䀅耛1"⢀',
    "Sun Feb 20 2022 8 8":
        "̀\ud81fŰ฀ꀃꀯĘ픁ꠃ䎀〄ր؀栁耂\nHĀ\u0000ȀЁင쀋\u0012dØpԠل耭Vᑀ",
    "Mon Feb 21 2022 8 8": "̀\ud80cǠᖀ㐆 〆胀쀏2ǈޠᶂè֣聞xŠ䁪␁ \u0014Èŀڀఀࠀ  ",
    "Tue Feb 22 2022 8 8":
        "̀\ud81f胸 갂\u0000Ęᬈ຀‌聒䐁怇聮¸ӣ䀦Ƙڠڀሀ瀁䀕,8ðǀƀԀȀ᠉(Ā儀",
    "Wed Feb 23 2022 8 8":
        "̀\ud818Àऀ退耧Ę䔀耔°ǀ쀸ðΠྀḀ搁퀏䀻Ђ뀋䀩¸ʠŀಀᨀᐜࠂ\u0000ɀĀ؀䰀萀ࠀ匃䊈\u0000",
    "Thu Feb 24 2022 8 8":
        "̀\ud840ꠍňɀ⠁\u0011耬怅ĩꀚ耚䰅怍耾Ǹ„聲ǰ݅耸ŠՀᜀḀ㈤ƈĐˀঀ̀Ȁ䐀䡨耀",
    "Fri Feb 25 2022 8 8":
        "̀\ud802聀ᴀ⌀\udca0䨂က耘ɠրሀ젃䀃̀ꀁ耎<pƢ⒀搂适䀘耑*Lę䄀",
    "Sat Feb 26 2022 8 8": "̀\ud841㠅聈р⁠ᗆˠ㜀祠⬀밁쀻Ё퀏䀹dʓ쀱ÔŒ‖HƐ ƀഀਙက",
    "Sun Feb 27 2022 8 8": "̀\ud800臀ᲂ֠ᜁᦅȐጀ堂䀙h`ŀᔀ㐀ƀ码퀇쎀㈀ꀝ舀噄ňƠˀᦀጲ ",
    "Mon Feb 28 2022 8 8":
        "̀\ud81fň 㳀Ѐ\b촃逌蘅쀕\u0014`ڀ਀䠁င쀋lpĠᢀ樀棠ીցnÌŰӣ\u0002耥B\u00040!ꈀ",
    "Tue Mar 01 2022 8 8":
        "̀\ud81eÐᨀ\ud805℁ᦅΠ쁰ր᐀က惠਀ \u0004耑\u00040ӀଁƐŀ܀⸒$ƈĐР@ʔက",
    "Wed Mar 02 2022 8 8":
        "̀\ud80f臀ᔀ전\u0000Ę䔈ᶀꀖ聖ŸǠᮀ昁꠆ 耜ŰԠಀ爁䀏̀᠀㠀桰ˀƀഀ耒,ĐɀʀЊ蠀",
    "Thu Mar 03 2022 8 8":
        "̀\ud810Ǡᬀ␅Ęɥɀᄀ䠁䀄0ÐÀȀ⠀怃胀ᰀ뀂ꀆ耞¸p⁀ڀⴀ嘈èƐܠཀᶀ㼀㸤x°٠ീ઀─䈀頨耀",
    "Fri Mar 04 2022 8 8": "̀\ud800Ĉ谆〆\bÀ̀਀적 耑ం怈耂\u0018瀁䰂ɰׄ耳jÄHÐ݂֠蠀",
    "Sat Mar 05 2022 8 8":
        "̀\ud817臨ἀ栂쀨ę蔂끀簇蘁죠ᴀ砀ༀ㈁䠁‘聬␄쀃\u0004ŀڀಀԀఀ堔䀀",
    "Sun Mar 06 2022 8 8":
        "̀\ud800Đᰀ䀷〆䀁؂䀊 ƀڀ؀ခ䀃΀硤΀ᘀ吁瀅‖耝zìဂ뀁怌슈\u0000",
    "Mon Mar 07 2022 8 8":
        "̀\ud818Pᘀ怃(ࣀ昨ࠀ倃䀌茀ば਀胠π܀ᨀꠂ耙tϐ䀆쒀⼀嘀됨ǀ඀଀ᄀ⨀鑨耀",
    "Tue Mar 08 2022 8 8":
        "̀\ud81eŸր㰂怀〆ꀍxϠเ㴀琐ເ㼀簰ૈ胜ű―Ô̐඀ᤀ㐀᠀ 老\u0004ń\u0000",
    "Wed Mar 09 2022 8 8":
        "̀\ud808ĠऀꐄᦅȀ삈瀲ÐÀȀ⠀态쀋*hΠྀḀ레ɀ㄀氀堀蠁倂怌쀛耗ਠ\u0000",
    "Thu Mar 10 2022 8 8":
        "̀\ud803\b຀耀ĘӋȐኀ吘ೀ଀㰂섀퇀ᤂϠຄ聰ˀ઀Ԁ฀氀쐀蠁怄쀈老\u0004ਠ\u0000",
    "Fri Mar 11 2022 8 8":
        "̀\ud80f聨̀ࠅ※Ęૅ˰怬」䀳ÜŰӀᔀ䐂ဉ袀ဂ䀈\u0010P̀ƀȀ᐀Ⰰ吀⠁逃态쀂䒀ᤀ㨈⢀",
    "Sat Mar 12 2022 8 8":
        "̀\ud841᠈膰஀硨֌ ─ꠄ촀鰁瀍쀏¼ǰ䀫ᨀ퀍䀱Ⰱ怉耢H Ā؀ഀᤞ\u001c䐁倁ꀃ虄\u0000",
    "Sun Mar 13 2022 8 8":
        "̀\ud800à຀Ӏ✁ᥕȀࠀ쀁\u0006\u0014Őր᐀、쀇膀㐁퀇쀏2㠅ꀆ耵*ĈɠÀ䀂頪摀",
    // MISSING Sun Mar 14
    "Tue Mar 15 2022 8 8":
        "̀\ud814Ÿ઀堁䀰 ࣀ昨ఀ瀁ꀎ耾xˠ΀ऀꐁ逇䀛¬〃쀋4ƨ뀂掀ࢁlÈ䠀쀆腀ࠀ 䠴䀀",
    "Wed Mar 16 2022 8 8": "̀\ud808ĠЀⰁꄁ᠄씂À蠂䀙h`XŐŀ܀氁蠁—耲ǈޠເᾀἄ¬젃倆杀஀✈ ",
    "Thu Mar 17 2022 8 8": "̀\ud81f胸຀耀〆끆נހᘁ順ꀆ耒ň֣耲ǈဏ\\Ő̀ฉ\u0018(Ɛ̀؀Ѐစက",
    "Fri Mar 18 2022 8 8":
        "̀\ud81f臘ሀ㐄쀆ęꌈྀ밅怖聒Èܠἀ㰁瀅䀖\u0018@؀ᨀ⠸рҀᤀ㘀ᰀ␈°Ǡවᦀጀ⨀䐁॑\u0000",
    "Sat Mar 19 2022 8 8":
        "̀\ud807膨⇘઀䀂〙訁杁렅쀓⨂倈䀑Ä〃䀉¤Ɛ݀胴ΐྀ㨀㐀码焐ŀಀऀ∀Ѐခ↑\u0000",
    "Sun Mar 20 2022 8 8":
        "̀\ud804 ଀㐆ꀚǸࣀ昨᠀䀂\u0000̀送䀄蜀䐂က䀅᠄얀Ⳉీᬀ฀尀砨Π߀เಀᴀ稈梀",
    "Mon Mar 21 2022 8 8":
        "̀\ud980倗øເ灠ᗘ āࠄ䀱ÔÐɀิÀ଀ᰀ栁⤀搁큏ü蠃퀇‏耝<㈠\u0000",
    "Tue Mar 22 2022 8 8": '̀\ud81f膘ڀ㠄ꀰĘ˅ǰក갘π㜌͑쀉¤ˠ È͠րᄀ葉ꀓ.怀倀 耓"HPƠ儀',
    "Wed Mar 23 2022 8 8": "̀\ud840栙Đ઀砂㊀鲨━ࠄ䀕Ô퀂䀎Ø뀄輀退䀀 @̀ഀఀကꀁ愐׀ހഀᰲ ",
    "Thu Mar 24 2022 8 8":
        "̀\ud800İᤀ㠐㼁᠄攀䀒 ƃXġ쀶ÄĐՀ─萀ခ䨀鰁瀍쀇耋V´h 耆\bP°ŐˠԠـຂ>摀",
    "Fri Mar 25 2022 8 8":
        "̀\ud841ꠉƀ؀灠හӠᜀ項䴀㐀逌䀶XɠࡀĀ᠀ 謀저ꀃ4(Āă쀂\u0014Ԑ\u0000",
    "Sat Mar 26 2022 8 8":
        "̀\ud807耈ႀ쐄쀴ǐࣀ昨ᮀ封ぬ\u0014䀕Ôࠍ耖ሂ \u0014@̀ꀲ\u00148Űʠր̀Ѐ⠀　瀀탑\u0000",
    "Sun Mar 27 2022 8 8": "̀\ud803Ðಀꐁ‷ę蔀တ聄Š඀฀렂ꀂ耲Ð̀Ȁ⠀态샀㨀݀ᚙbÔh°١䐀",
    "Mon Mar 28 2022 8 8":
        "̀\ud81e臐ހ찀쀤Āࣀ㘨䬃ꀌ耼ǰ怍TŰԠ ᨀ堅怗踁렂茀☄ƨ̐Ƞրጀ℀䨀᐀࢐@Ď蠀",
    "Tue Mar 29 2022 8 8":
        "̀\ud800(ᆀ\ud802쀸《䀁\u0018\u0010쀥ᰂ瀅쀓T͐ీ胈ġ…ɀࠀࠀ　ကꀁ态쀃ń\u0000",
    "Wed Mar 30 2022 8 8":
        "̀\ud813肐ᢀ각쀽Ęᔷ\u0014 ႀ∀뀄쀑H \u0000က ꁠᤀ永ڠ઀ጀ昀\udc00砂ˀڀऒ¸ňːΡ䐀",
    "Thu Mar 31 2022 8 8":
        "̀\ud800Ơ؀㠄怿ሌ씀䀒Āԏ ʀ଀ᰀ栂ꀂ肀\ud803 耢\b`Ӏࡀ኉N\\ƹ퀁怃쀗耟ਠ\u0000",
    "Fri Apr 01 2022 8 8":
        "̀\ud802\u0010̀ᐂ‿《Ҡ઀樁袐଀氀瀅쀔耲ð̀฀堁䀃\u001c<tøǈϐݡ䐀",
    "Sat Apr 02 2022 8 8":
        "̀\ud800頉@܀瀂々言ꔁ㥐ጀ吂倈䀑Ä΀਀좰ࢀ☀᠀ \u0000@ Ā؇耆,Th蠁茢\u0000",
    "Sun Apr 03 2022 8 8":
        "̀\ud81c胨Ҁ㰄昊ꁺ᠌聸߀ᴀ码쀃\u000eXŐ׀܀ᐁ逆쀘踀栁怕舀㸀밈Ͱˠূ蠀",
};
