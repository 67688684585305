import { Page } from "../components/Page";
import "react-datepicker/dist/react-datepicker.css";
import { ControlsAndBoard } from "../components/ControlsAndBoard";
import { useIsClient } from "../utils/use-is-client";
import { Helmet } from "react-helmet";

const IndexPage = () => {
    const { isClient } = useIsClient();
    return (
        <Page pagePath="/">
            <Helmet>
                <title>Daily Train Tracks</title>
                <meta
                    name="description"
                    content="Play our daily train track puzzles! Hundreds of past puzzles, with a new puzzle released every day."
                />
                <script
                    defer
                    data-domain="dailytraintracks.com"
                    src="https://plausible.io/js/plausible.js"
                ></script>
            </Helmet>
            {isClient && <ControlsAndBoard />}
        </Page>
    );
};

export default IndexPage;
