import { addTrackTypesToPath } from "../generator/generator";
import { TrackType } from "./board-utils";

export const solutionToCorrectCellState = (
    solution: { x: number; y: number }[],
    boardDimensions: { x: number; y: number }
) => {
    const cellState: TrackType[][] = [];
    for (let y = 0; y < boardDimensions.y; y++) {
        const row: TrackType[] = [];
        for (let x = 0; x < boardDimensions.x; x++) {
            row.push("empty");
        }
        cellState.push(row);
    }

    const path = addTrackTypesToPath(boardDimensions, solution);
    for (const cell of path) {
        cellState[cell.y][cell.x] = cell.trackType;
    }

    return cellState;
};

const isCellValid = (tt: TrackType, correctTT: TrackType): boolean => {
    if (tt === correctTT) {
        return true;
    }

    if (tt === "crossed" && correctTT === "empty") {
        return true;
    }

    return false;
};

export const isPuzzleComplete = (
    newCellState: TrackType[][],
    correctCellState: TrackType[][]
) => {
    for (let y = 0; y < newCellState.length; y++) {
        const row = newCellState[y];
        for (let x = 0; x < row.length; x++) {
            if (!isCellValid(row[x], correctCellState[y][x])) {
                return false;
            }
        }
    }

    return true;
};
