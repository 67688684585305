import rough from "roughjs/bin/rough";

export type TrackType =
    | "up-down"
    | "left-right"
    | "up-right"
    | "right-down"
    | "down-left"
    | "left-up"
    | "empty"
    | "crossed"
    | "question";

export const cellIsFixed = (
    position: { x: number; y: number },
    fixedCells: { x: number; y: number }[]
) => {
    return (
        fixedCells.findIndex((c) => c.x === position.x && c.y === position.y) >=
        0
    );
};

export type Puzzle = {
    seed: string;
    boardDimensions: {
        x: number;
        y: number;
    };
    fixedCells: {
        x: number;
        y: number;
    }[];
    solution: {
        x: number;
        y: number;
    }[];
};

export type BoardState = {
    boardDimensions: {
        x: number;
        y: number;
    };
    fixedCells: {
        x: number;
        y: number;
    }[];
    solution: {
        x: number;
        y: number;
    }[];
    trackCounts: {
        x: number[];
        y: number[];
    };
    cellState: TrackType[][];
};

export const BOARD_SEED = 11;
export const BOARD_PADDING = 50;

// All this does is scale the RoughJS roughness, in effect
export const CELL_SIZE = 50;

export const calculateBoardSize = (boardDimensions: {
    x: number;
    y: number;
}) => {
    // Padding is included
    return {
        outer: {
            x: CELL_SIZE * boardDimensions.x + 2 * BOARD_PADDING,
            y: CELL_SIZE * boardDimensions.y + 2 * BOARD_PADDING,
        },
        inner: {
            x: CELL_SIZE * boardDimensions.x,
            y: CELL_SIZE * boardDimensions.y,
        },
    };
};

export const cellCoordToBoardCoord = (x: number, y: number) => {
    return {
        x: BOARD_PADDING + x * CELL_SIZE,
        y: BOARD_PADDING + y * CELL_SIZE,
    };
};

export const drawRoughSvgBoard = (
    svgElement: SVGSVGElement,
    boardDimensions: { x: number; y: number }
) => {
    const rc = rough.svg(svgElement);
    const boardSize = calculateBoardSize(boardDimensions);

    const outline = rc.rectangle(
        BOARD_PADDING,
        BOARD_PADDING,
        boardSize.inner.x,
        boardSize.inner.y,
        { seed: BOARD_SEED, roughness: 1, strokeWidth: 4 }
    ); // x, y, width, height
    svgElement.appendChild(outline);

    for (let x = 0; x < boardDimensions.x; x++) {
        const xCoord = BOARD_PADDING + x * CELL_SIZE;
        const xLine = rc.line(
            xCoord,
            BOARD_PADDING,
            xCoord,
            boardSize.outer.y - BOARD_PADDING,
            { seed: x + BOARD_SEED, roughness: 0.6, strokeWidth: 1 }
        );
        svgElement.appendChild(xLine);
    }

    for (let y = 0; y < boardDimensions.y; y++) {
        const yCoord = BOARD_PADDING + y * CELL_SIZE;
        const yLine = rc.line(
            BOARD_PADDING,
            yCoord,
            boardSize.outer.x - BOARD_PADDING,
            yCoord,
            { seed: y + BOARD_SEED, roughness: 0.6, strokeWidth: 1 }
        );
        svgElement.appendChild(yLine);
    }
};

export const fillCellState = (
    cellState: TrackType[][],
    boardDimensions: {
        x: number;
        y: number;
    }
) => {
    while (cellState.length < boardDimensions.y) {
        cellState.push([]);
    }

    for (const row of cellState) {
        while (row.length < boardDimensions.x) {
            row.push("empty");
        }
    }

    return cellState;
};
