import { BOARD_PADDING, CELL_SIZE, TrackType } from "../utils/board-utils";
import { useGameContext } from "./GameContext";

interface Props {
    trackIndex: number;
    axis: "x" | "y";
    count: number;
}

const getTrackCountPositionX = (index: number) => {
    return {
        x: BOARD_PADDING + index * CELL_SIZE,
        y: 0,
    };
};

const getTrackCountPositionY = (index: number) => {
    return {
        x: 0,
        y: BOARD_PADDING + index * CELL_SIZE,
    };
};

const getRowOrColumn = (
    axis: "x" | "y",
    position: number,
    cellState: TrackType[][]
): TrackType[] => {
    if (axis === "y") {
        return cellState[position];
    }

    return cellState.map((row) => row[position]);
};

const getValid = (
    count: number,
    axis: "x" | "y",
    position: number,
    cellState: TrackType[][]
): "valid" | "invalid" | "unknown" => {
    const rowOrColumn = getRowOrColumn(axis, position, cellState);
    const trackCount = rowOrColumn.reduce(
        (acc, tt) => (tt === "crossed" || tt === "empty" ? acc : acc + 1),
        0
    );

    if (trackCount === count) {
        return "valid";
    }

    if (trackCount > count) {
        return "invalid";
    }

    return "unknown";
};

export const TrackCount: React.FC<Props> = ({ trackIndex, axis, count }) => {
    const { cellState } = useGameContext();
    const validity = getValid(count, axis, trackIndex, cellState);

    const coordinates =
        axis === "x"
            ? getTrackCountPositionX(trackIndex)
            : getTrackCountPositionY(trackIndex);

    return (
        <svg
            width={CELL_SIZE}
            height={CELL_SIZE}
            x={coordinates.x}
            y={coordinates.y}
            style={{ userSelect: "none" }}
        >
            <text
                width={50}
                x="50%"
                y="50%"
                dominantBaseline="middle"
                textAnchor="middle"
                fontSize={30}
                fill={
                    validity === "valid"
                        ? "green"
                        : validity === "invalid"
                        ? "red"
                        : "black"
                }
            >
                {count}
            </text>
        </svg>
    );
};
