import { SaveFile } from "../components/GameContext";
import { decodePregeneratedPuzzle } from "../generate-savefiles";
import { pregeneratedPuzzles } from "../pregenerated-puzzles";
import {
    backtrackingSolver,
    Puzzle as SolverPuzzle,
} from "../solver/backtracking/solver";
import { TrackType } from "../utils/board-utils";
import { solutionToTrackCount } from "../utils/solution-to-track-count";

const seedrandom = require("seedrandom");

const positionIsInBounds = (
    pos: { x: number; y: number },
    boardDimensions: { x: number; y: number }
): boolean => {
    if (pos.x < 0 || pos.y < 0) {
        return false;
    }

    if (pos.x >= boardDimensions.x || pos.y >= boardDimensions.y) {
        return false;
    }

    return true;
};

const getCandidates = (
    cell: { x: number; y: number },
    filledCells: boolean[][],
    boardDimensions: { x: number; y: number }
) => {
    const { x, y } = cell;
    const candidates = [
        { x: x - 1, y },
        { x: x + 1, y },
        { x, y: y - 1 },
        { x, y: y + 1 },
    ].filter(
        (p) => positionIsInBounds(p, boardDimensions) && !filledCells[p.y][p.x]
    );

    return candidates;
};

const shuffle = <T>(arr: T[], rng: () => number): T[] => {
    let currentIndex = arr.length,
        randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex !== 0) {
        // Pick a remaining element...
        randomIndex = Math.floor(rng() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [arr[currentIndex], arr[randomIndex]] = [
            arr[randomIndex],
            arr[currentIndex],
        ];
    }

    return arr;
};

const isValidPath = (
    boardDimensions: { x: number; y: number },
    path: { x: number; y: number }[],
    pathLengthMinimum: number,
    filledCells: boolean[][]
) => {
    if (path.length < pathLengthMinimum) {
        return false;
    }

    const finalCell = path[path.length - 1];
    const pathEndsOnEdge =
        finalCell.x === 0 ||
        finalCell.y === 0 ||
        finalCell.x === boardDimensions.x - 1 ||
        finalCell.y === boardDimensions.y - 1;

    if (!pathEndsOnEdge) {
        return false;
    }

    for (let y = 0; y < boardDimensions.y; y++) {
        const row = filledCells[y];
        const sum = row.reduce((acc, b) => (b ? acc + 1 : acc), 0);
        if (sum === 0) {
            return false;
        }
    }

    for (let x = 0; x < boardDimensions.y; x++) {
        const column = filledCells.map((r) => r[x]);
        const sum = column.reduce((acc, b) => (b ? acc + 1 : acc), 0);
        if (sum === 0) {
            return false;
        }
    }

    return true;
};

const MAX_RECURSIONS = 100;

const rGeneratePath = (
    boardDimensions: { x: number; y: number },
    path: { x: number; y: number }[],
    pathLengthMinimum: number,
    filledCells: boolean[][],
    rng: () => number,
    counter = 0
): "invalid" | { x: number; y: number }[] => {
    if (counter >= MAX_RECURSIONS) {
        return "invalid";
    }

    if (isValidPath(boardDimensions, path, pathLengthMinimum, filledCells)) {
        return path;
    }

    const candidates = shuffle(
        getCandidates(path[path.length - 1], filledCells, boardDimensions),
        rng
    );

    if (candidates.length === 0) {
        return "invalid";
    }

    for (const candidate of candidates) {
        filledCells[candidate.y][candidate.x] = true;
        const output = rGeneratePath(
            boardDimensions,
            [...path, candidate],
            pathLengthMinimum,
            filledCells,
            rng,
            counter + 1
        );

        if (output !== "invalid") {
            // Success
            return output;
        }

        // Unsuccess
        filledCells[candidate.y][candidate.x] = false;
    }

    return "invalid";
};

const generatePath = (
    boardDimensions: { x: number; y: number },
    coverage: number, // Percentage of board (0 - 1)
    rng: () => number
) => {
    const pathLengthMinimum = Math.floor(
        boardDimensions.x * boardDimensions.y * coverage
    );
    const startingAxis = rng() < 0.5 ? "x" : "y";
    const startingCoord = Math.floor(rng() * boardDimensions[startingAxis]);
    const startingPosition = {
        x:
            startingAxis === "x"
                ? startingCoord
                : rng() < 0.5
                ? 0
                : boardDimensions.x - 1,
        y:
            startingAxis === "y"
                ? startingCoord
                : rng() < 0.5
                ? 0
                : boardDimensions.y - 1,
    };

    const filledCells: boolean[][] = [];

    for (let y = 0; y < boardDimensions.y; y++) {
        const row = [];
        for (let x = 0; x < boardDimensions.x; x++) {
            row.push(false);
        }
        filledCells.push(row);
    }

    filledCells[startingPosition.y][startingPosition.x] = true;

    return rGeneratePath(
        boardDimensions,
        [startingPosition],
        pathLengthMinimum,
        filledCells,
        rng
    );
};

type Direction = "up" | "down" | "left" | "right";

const pairToDirection = (
    from: { x: number; y: number },
    to: { x: number; y: number }
): Direction => {
    if (from.x < to.x) {
        return "right";
    }

    if (from.x > to.x) {
        return "left";
    }

    if (from.y < to.y) {
        return "down";
    }

    if (from.y > to.y) {
        return "up";
    }

    throw new Error("Invalid pair");
};

const trackTypes = [
    "up-down",
    "left-right",
    "up-right",
    "right-down",
    "down-left",
    "left-up",
] as const;

const directionsToTrackType = (d1: Direction, d2: Direction): TrackType => {
    const trackType = trackTypes.find(
        (tt) => tt.includes(d1) && tt.includes(d2)
    );
    if (!trackType) {
        throw new Error("Invalid tile when finding tt from directions");
    }

    return trackType;
};

const trioToTrackType = (
    prev: { x: number; y: number },
    current: { x: number; y: number },
    next: { x: number; y: number }
) => {
    const d1 = pairToDirection(current, prev);
    const d2 = pairToDirection(current, next);
    return directionsToTrackType(d1, d2);
};

const startOrEndToTrackType = (
    start: { x: number; y: number },
    next: { x: number; y: number },
    boardDimensions: { x: number; y: number }
) => {
    if (start.x === 0) {
        return trioToTrackType({ x: -1, y: start.y }, start, next);
    }

    if (start.x === boardDimensions.x - 1) {
        return trioToTrackType(
            { x: boardDimensions.x, y: start.y },
            start,
            next
        );
    }

    if (start.y === 0) {
        return trioToTrackType({ x: start.x, y: -1 }, start, next);
    }

    if (start.y === boardDimensions.y - 1) {
        return trioToTrackType(
            { x: start.x, y: boardDimensions.y },
            start,
            next
        );
    }

    throw new Error("Invalid start or end tile");
};

export const addTrackTypesToPath = (
    boardDimensions: { x: number; y: number },
    path: { x: number; y: number }[]
): { x: number; y: number; trackType: TrackType }[] => {
    const withTT: { x: number; y: number; trackType: TrackType }[] = [
        {
            ...path[0],
            trackType: startOrEndToTrackType(path[0], path[1], boardDimensions),
        },
    ];
    for (let i = 1; i < path.length - 1; i++) {
        withTT.push({
            ...path[i],
            trackType: trioToTrackType(path[i - 1], path[i], path[i + 1]),
        });
    }

    withTT.push({
        ...path[path.length - 1],
        trackType: startOrEndToTrackType(
            path[path.length - 1],
            path[path.length - 2],
            boardDimensions
        ),
    });

    return withTT;
};

const numHelperTiles = (boardDimensions: { x: number; y: number }) => {
    const cellTotal = boardDimensions.x * boardDimensions.y;

    return Math.ceil(cellTotal / 18);
};

const createPuzzle = (
    boardDimensions: { x: number; y: number },
    path: { x: number; y: number; trackType: TrackType }[],
    rng: () => number
): SolverPuzzle => {
    const counts = solutionToTrackCount(path, boardDimensions);

    const indicesToRemove = [];
    for (let i = 1; i < path.length - 1; i++) {
        indicesToRemove.push(i);
    }
    shuffle(indicesToRemove, rng);

    for (let i = 0; i < numHelperTiles(boardDimensions); i++) {
        // Helper tiles
        indicesToRemove.shift();
    }

    const fixedCells: (
        | { x: number; y: number; trackType: TrackType }
        | undefined
    )[] = [...path];

    for (const i of indicesToRemove) {
        const removedTrack = fixedCells[i];
        fixedCells[i] = undefined;
        const fixedCellsFiltered = fixedCells.filter((f) => f);
        const puzzle: SolverPuzzle = {
            boardDimensions,
            start: path[0],
            end: path[path.length - 1],
            counts,
            fixedCells: fixedCellsFiltered as {
                x: number;
                y: number;
                trackType: TrackType;
            }[],
        };

        const solution = backtrackingSolver(puzzle, true);
        if (solution === "invalid" || solution === "not-unique") {
            fixedCells[i] = removedTrack;
            return {
                ...puzzle,
                fixedCells: fixedCells.filter((f) => f) as {
                    x: number;
                    y: number;
                    trackType: TrackType;
                }[],
            };
        }
    }

    const fixedCellsFiltered = fixedCells.filter((f) => f);
    return {
        boardDimensions,
        start: path[0],
        end: path[path.length - 1],
        counts,
        fixedCells: fixedCellsFiltered as {
            x: number;
            y: number;
            trackType: TrackType;
        }[],
    };
};

const createCellState = (
    puzzle: Pick<SolverPuzzle, "boardDimensions" | "fixedCells">
): TrackType[][] => {
    const cellState: TrackType[][] = [];
    for (let y = 0; y < puzzle.boardDimensions.y; y++) {
        const row: TrackType[] = [];
        for (let x = 0; x < puzzle.boardDimensions.x; x++) {
            row.push("empty");
        }
        cellState.push(row);
    }

    for (const fc of puzzle.fixedCells) {
        cellState[fc.y][fc.x] = fc.trackType;
    }

    return cellState;
};

const PATH_COVERAGE_MIN = 0.2; // Fraction of board covered by path
const FIXED_CELL_COVERAGE_MAX = 0.2; // Fraction of path covered by fixed cells

export const generate = (
    boardDimensions: {
        x: number;
        y: number;
    },
    seedDateString: string
): SaveFile => {
    const seed = `${seedDateString} ${boardDimensions.x} ${boardDimensions.y}`;
    if (seed in pregeneratedPuzzles) {
        const pregenPuzzle = decodePregeneratedPuzzle(
            pregeneratedPuzzles[seed as keyof typeof pregeneratedPuzzles]
        );
        const puzzle = {
            seed,
            ...pregenPuzzle,
        };

        const solutionWithTrackTypes = addTrackTypesToPath(
            puzzle.boardDimensions,
            puzzle.solution
        );
        const coordToTrackType: Record<string, TrackType> = {};
        for (const c of solutionWithTrackTypes) {
            coordToTrackType[`${c.x}-${c.y}`] = c.trackType;
        }
        const fixedCells = puzzle.fixedCells.map((fc) => ({
            x: fc.x,
            y: fc.y,
            trackType: coordToTrackType[`${fc.x}-${fc.y}`],
        }));
        return {
            puzzle,
            cellState: createCellState({
                boardDimensions: puzzle.boardDimensions,
                fixedCells,
            }),
        };
    }

    let i = 0;
    while (true) {
        // console.log(`Generation attempt ${i}`);
        const rng = seedrandom(`${seed} ${i}`);
        const path = generatePath(boardDimensions, PATH_COVERAGE_MIN, rng);
        if (path === "invalid") {
            i++;
            continue;
        }
        const puzzle = createPuzzle(
            boardDimensions,
            addTrackTypesToPath(boardDimensions, path),
            rng
        );

        if (puzzle.fixedCells.length / path.length > FIXED_CELL_COVERAGE_MAX) {
            i++;
            continue;
        }

        return {
            puzzle: {
                seed,
                boardDimensions,
                fixedCells: puzzle.fixedCells,
                solution: path,
            },
            cellState: createCellState(puzzle),
        };
    }
};
