import { compress, decompress } from "lz-string";
import { generate } from "./generator/generator";
import {
    decodeCoordinateArray,
    encodeCoordinateArray,
} from "./utils/board-state-encoding";
import { Puzzle } from "./utils/board-utils";

type PregeneratedPuzzle = Omit<Puzzle, "seed">;

const encodePregeneratedPuzzle = (puzzle: PregeneratedPuzzle): string => {
    const { boardDimensions, fixedCells, solution } = puzzle;
    const fixedCellsCode = encodeCoordinateArray(fixedCells, boardDimensions);
    const solutionCode = encodeCoordinateArray(solution, boardDimensions);
    const fixedCellsLength = fixedCellsCode.length
        .toString(32)
        .padStart(2, "0");
    const solutionLength = solutionCode.length.toString(32).padStart(2, "0");
    const xString = boardDimensions.x.toString(32).padStart(2, "0");
    const yString = boardDimensions.y.toString(32).padStart(2, "0");

    return compress(
        `${xString}${yString}${fixedCellsLength}${fixedCellsCode}${solutionLength}${solutionCode}`
    );
};

export const decodePregeneratedPuzzle = (s: string): PregeneratedPuzzle => {
    const decompressed = decompress(s) ?? "";
    const xString = decompressed.substring(0, 2);
    const yString = decompressed.substring(2, 4);
    const fixedCellsLength = parseInt(decompressed.substring(4, 6), 32);
    const fixedCellsCode = decompressed.substring(6, 6 + fixedCellsLength);
    const solutionLength = parseInt(
        decompressed.substring(6 + fixedCellsLength, 6 + fixedCellsLength + 2),
        32
    );
    const solutionCode = decompressed.substring(
        6 + fixedCellsLength + 2,
        6 + fixedCellsLength + 2 + solutionLength
    );

    const boardDimensions = {
        x: parseInt(xString, 32),
        y: parseInt(yString, 32),
    };

    const fixedCells = decodeCoordinateArray(fixedCellsCode, boardDimensions);
    const solution = decodeCoordinateArray(solutionCode, boardDimensions);

    return {
        boardDimensions,
        fixedCells,
        solution,
    };
};

export const generatePuzzle = (
    boardDimensions: { x: number; y: number },
    seedDateString: string
): string => {
    const { puzzle } = generate(boardDimensions, seedDateString);
    const code = encodePregeneratedPuzzle(puzzle);
    return code;
};

export const generatePuzzles = () => {
    const codes: Record<string, string> = {};
    const start = 71;
    for (let i = start; i < start + 10; i++) {
        const date = new Date();
        date.setDate(date.getDate() + i);
        const seed = date.toDateString();
        const code = generatePuzzle({ x: 8, y: 8 }, seed);
        console.log(i);
        codes[`${seed} 8 8`] = code;
    }
    document.addEventListener("click", () => {
        navigator.clipboard.writeText(JSON.stringify(codes));
    });
};
