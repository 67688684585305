import { TrackType } from "../../utils/board-utils";

export type Puzzle = {
    boardDimensions: { x: number; y: number };
    start: { x: number; y: number; trackType: TrackType };
    end: { x: number; y: number; trackType: TrackType };
    fixedCells: { x: number; y: number; trackType: TrackType }[];
    counts: { x: number[]; y: number[] };
};

type Direction = "up" | "down" | "left" | "right";

const trackTypeToDirections = (tt: TrackType): [Direction, Direction] => {
    const parts = tt.split("-");
    return parts as [Direction, Direction];
};

const positionIsInBounds = (
    pos: { x: number; y: number },
    boardDimensions: { x: number; y: number }
): boolean => {
    if (pos.x < 0 || pos.y < 0) {
        return false;
    }

    if (pos.x >= boardDimensions.x || pos.y >= boardDimensions.y) {
        return false;
    }

    return true;
};

const positionInDirection = (
    direction: Direction,
    position: { x: number; y: number }
): { x: number; y: number } => {
    const { x, y } = position;
    switch (direction) {
        case "up":
            return { x, y: y - 1 };
        case "down":
            return { x, y: y + 1 };
        case "left":
            return { x: x - 1, y };
        case "right":
            return { x: x + 1, y };
    }
};

const positionedTrackTypeToFilledCells = (
    cell: { x: number; y: number; trackType: TrackType },
    boardDimensions: { x: number; y: number }
): { x: number; y: number }[] => {
    const directions = trackTypeToDirections(cell.trackType);
    const adjacentCoordinates = directions
        .map((direction) => positionInDirection(direction, cell))
        .filter((p) => positionIsInBounds(p, boardDimensions));

    if (adjacentCoordinates.length === 1) {
        return [{ x: cell.x, y: cell.y }, adjacentCoordinates[0]];
    }

    console.assert(adjacentCoordinates.length === 2);

    return [
        adjacentCoordinates[0],
        { x: cell.x, y: cell.y },
        adjacentCoordinates[1],
    ];
};

const vectorsEqual = (
    v1: { x: number; y: number },
    v2: { x: number; y: number }
) => {
    return v1.x === v2.x && v1.y === v2.y;
};

const solutionsDiffer = (
    s1: { x: number; y: number }[],
    s2: { x: number; y: number }[]
) => {
    if (s1.length !== s2.length) {
        return true;
    }

    for (let i = 0; i < s1.length; i++) {
        if (!vectorsEqual(s1[i], s2[i])) {
            return true;
        }
    }

    return false;
};

const validCounts = (
    counts: { x: number[]; y: number[] },
    filledCells: boolean[][]
) => {
    for (let x = 0; x < counts.x.length; x++) {
        // Sum each column
        const column = filledCells.map((row) => row[x]);
        const sum = column.reduce((acc, curr) => (curr ? acc + 1 : acc), 0);
        if (sum > counts.x[x]) {
            return false;
        }
    }

    for (let y = 0; y < counts.y.length; y++) {
        // Sum each row
        const row = filledCells[y];
        const sum = row.reduce((acc, curr) => (curr ? acc + 1 : acc), 0);
        if (sum > counts.y[y]) {
            return false;
        }
    }

    return true;
};

const isSolved = (
    solution: { x: number; y: number }[],
    start: { x: number; y: number },
    end: { x: number; y: number },
    counts: { x: number[]; y: number[] },
    filledCells: boolean[][],
    startingSnippet: { x: number; y: number }[],
    endingSnippet: { x: number; y: number }[],
    snippets: { x: number; y: number }[][]
) => {
    if (!vectorsEqual(start, solution[0])) {
        return false;
    }

    if (!vectorsEqual(end, solution[solution.length - 1])) {
        return false;
    }

    // Assuming solution is continuous path (only right angles)
    // Assuming filledCells contains exactly the cells in the solution

    for (let x = 0; x < counts.x.length; x++) {
        // Sum each column
        const column = filledCells.map((row) => row[x]);
        const sum = column.reduce((acc, curr) => (curr ? acc + 1 : acc), 0);
        if (sum !== counts.x[x]) {
            return false;
        }
    }

    for (let y = 0; y < counts.y.length; y++) {
        // Sum each row
        const row = filledCells[y];
        const sum = row.reduce((acc, curr) => (curr ? acc + 1 : acc), 0);
        if (sum !== counts.y[y]) {
            return false;
        }
    }

    // Make sure all fixed cells are in solution
    // Assuming filledCells contains exactly the cells in the solution
    for (const p of startingSnippet) {
        if (!filledCells[p.y][p.x]) {
            return false;
        }
    }
    for (const p of endingSnippet) {
        if (!filledCells[p.y][p.x]) {
            return false;
        }
    }
    for (const p of snippets.flat()) {
        if (!filledCells[p.y][p.x]) {
            return false;
        }
    }

    return true;
};

const getCandidates = (
    cell: { x: number; y: number },
    filledCells: boolean[][],
    boardDimensions: { x: number; y: number }
) => {
    const { x, y } = cell;
    const candidates = [
        { x: x - 1, y },
        { x: x + 1, y },
        { x, y: y - 1 },
        { x, y: y + 1 },
    ].filter(
        (p) => positionIsInBounds(p, boardDimensions) && !filledCells[p.y][p.x]
    );

    return candidates;
};

const rSolve = (
    boardDimensions: { x: number; y: number },
    start: { x: number; y: number },
    end: { x: number; y: number },
    counts: { x: number[]; y: number[] },
    filledCells: boolean[][],
    solution: { x: number; y: number }[],
    startingSnippet: { x: number; y: number }[],
    endingSnippet: { x: number; y: number }[],
    snippets: { x: number; y: number }[][],
    checkUniqueness = false,
    foundSolution: { x: number; y: number }[] | undefined = undefined
): { x: number; y: number }[] | "invalid" | "not-unique" => {
    let _foundSolution = foundSolution;
    if (!validCounts(counts, filledCells)) {
        return "invalid";
    }

    if (
        isSolved(
            solution,
            start,
            end,
            counts,
            filledCells,
            startingSnippet,
            endingSnippet,
            snippets
        )
    ) {
        return solution;
    }

    const candidates = getCandidates(
        solution[solution.length - 1],
        filledCells,
        boardDimensions
    );

    if (candidates.length === 0) {
        return "invalid";
    }

    for (const candidate of candidates) {
        // If there are multiple matching snippets, this is not a valid candidate
        // Otherwise the puzzle is invalid
        let matchedSnippet = false;
        let toAppend = [candidate];
        for (const snippet of snippets) {
            if (snippet.some((p) => filledCells[p.y][p.x])) {
                // Part of the snippet is already in the solution
                // (Currently has to be the middle square)
                continue;
            }

            const matchStart = vectorsEqual(snippet[0], candidate);
            const matchEnd = vectorsEqual(
                snippet[snippet.length - 1],
                candidate
            );
            if (matchStart || matchEnd) {
                if (matchedSnippet) {
                    // Multiple candidates
                    continue;
                }

                matchedSnippet = true;

                toAppend = [...snippet];

                if (matchEnd) {
                    toAppend.reverse();
                }
            }
        }

        const matchesEndSnippet = vectorsEqual(endingSnippet[0], candidate);
        if (matchesEndSnippet) {
            if (matchedSnippet) {
                // Multiple candidates
                continue;
            }

            toAppend = [...endingSnippet];
        }

        for (const p of toAppend) {
            filledCells[p.y][p.x] = true;
        }

        const nextSolution = [...solution, ...toAppend];
        // TODO filled cells
        const output = rSolve(
            boardDimensions,
            start,
            end,
            counts,
            filledCells,
            nextSolution,
            startingSnippet,
            endingSnippet,
            snippets,
            checkUniqueness,
            _foundSolution
        );

        if (output === "not-unique") {
            return output;
        }

        if (output !== "invalid") {
            // Solved!
            if (_foundSolution && solutionsDiffer(_foundSolution, output)) {
                return "not-unique";
            }
            _foundSolution = output;

            if (!checkUniqueness) {
                return output;
            }
        }

        // Solution not valid: continue
        // Undo toAppend
        // Assumption here that these were all false to start with
        for (const p of toAppend) {
            filledCells[p.y][p.x] = false;
        }
    }

    if (_foundSolution) {
        return _foundSolution;
    }

    // No valid candidates
    return "invalid";
};

const fillFilledCells = (
    positions: { x: number; y: number }[],
    filledCells: boolean[][]
) => {
    for (const position of positions) {
        filledCells[position.y][position.x] = true;
    }
};

export const backtrackingSolver = (puzzle: Puzzle, checkUniqueness = false) => {
    // Assuming fixed cells doens't include start and end for now?

    const startingSnippet = positionedTrackTypeToFilledCells(
        puzzle.start,
        puzzle.boardDimensions
    );

    const endingSnippet = positionedTrackTypeToFilledCells(
        puzzle.start,
        puzzle.boardDimensions
    ).reverse();

    const middleSnippets = puzzle.fixedCells.map((c) =>
        positionedTrackTypeToFilledCells(c, puzzle.boardDimensions)
    );

    const filledCells: boolean[][] = [];

    for (let y = 0; y < puzzle.boardDimensions.y; y++) {
        const row = [];
        for (let x = 0; x < puzzle.boardDimensions.x; x++) {
            row.push(false);
        }
        filledCells.push(row);
    }

    fillFilledCells(startingSnippet, filledCells);

    const solution = rSolve(
        puzzle.boardDimensions,
        { x: puzzle.start.x, y: puzzle.start.y },
        { x: puzzle.end.x, y: puzzle.end.y },
        puzzle.counts,
        filledCells,
        startingSnippet,
        [...startingSnippet],
        endingSnippet,
        middleSnippets,
        checkUniqueness
    );

    return solution;
};
