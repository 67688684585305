import { useEffect } from "react";
import { css } from "@emotion/react";
import { calculateBoardSize, drawRoughSvgBoard } from "../utils/board-utils";
import { Track } from "./Track";
import { TrackCount } from "./TrackCount";
import { InteractCell } from "./InteractCell";
import { useGameContext } from "./GameContext";
import { DragProvider } from "./DragProvider";

const container = css`
    position: relative;
    width: 100%;
    height: 100%;
    flex: 1;
    overflow: auto;
    opacity: 0.5;
`;

const svgContainer = css`
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
`;

export const Board: React.FC = () => {
    const {
        puzzle: { boardDimensions, trackCounts },
        cellState,
    } = useGameContext();

    // TODO find a way to prevent this from drawing multiple times
    useEffect(() => {
        const element = document.getElementById("board");
        if (!element) {
            return;
        }

        drawRoughSvgBoard(element as unknown as SVGSVGElement, boardDimensions);
    }, [boardDimensions]);

    const boardSize = calculateBoardSize(boardDimensions).outer;
    const viewBox = `0 0 ${boardSize.x} ${boardSize.y}`;

    return (
        <DragProvider>
            <div css={container}>
                <svg id="board" css={svgContainer} viewBox={viewBox}>
                    {trackCounts.x.map((count, i) => (
                        <TrackCount
                            key={`count-x-${i}`}
                            axis="x"
                            trackIndex={i}
                            count={count}
                        />
                    ))}
                    {trackCounts.y.map((count, i) => (
                        <TrackCount
                            key={`count-y-${i}`}
                            axis="y"
                            trackIndex={i}
                            count={count}
                        />
                    ))}
                    {cellState.map((row, y) =>
                        row.map((trackType, x) => (
                            <Track
                                trackType={trackType}
                                x={x}
                                y={y}
                                key={`track-${x}-${y}`}
                            />
                        ))
                    )}
                    <InteractCell />
                </svg>
            </div>
        </DragProvider>
    );
};
