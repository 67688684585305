import React, { createContext, useContext, useEffect, useRef } from "react";

type DragContextValue = {
    isDragging: React.MutableRefObject<boolean>;
};

const DragContext = createContext<DragContextValue>(null as any);

export const useDragContext = () => {
    return useContext(DragContext);
};

export const DragProvider: React.FC = ({ children }) => {
    const isDragging = useRef(false);

    useEffect(() => {
        const dragStart = () => {
            isDragging.current = true;
        };
        const dragEnd = () => {
            isDragging.current = false;
        };
        document.addEventListener("mousedown", dragStart);
        document.addEventListener("mouseup", dragEnd);
        document.addEventListener("touchstart", dragStart);
        document.addEventListener("touchend", dragEnd);

        return () => {
            document.removeEventListener("mousedown", dragStart);
            document.removeEventListener("mouseup", dragEnd);
            document.removeEventListener("touchstart", dragStart);
            document.removeEventListener("touchend", dragEnd);
        };
    }, []);

    return (
        <DragContext.Provider value={{ isDragging }}>
            {children}
        </DragContext.Provider>
    );
};
